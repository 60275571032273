body {
    padding: 1em;
}

@logowidth: 8em;

.logo {
    display: inline;
    grid-area: logo;
    img {
        width: @logowidth;
    }
}
.header {
    display: inline;
    margin-left: 1em;
    vertical-align: bottom;
    text-transform: uppercase;
    font-family: Montserrat;
    font-size: 20pt;
    font-weight: bold;
}

.line {
    margin-top: 0.2em;
    grid-area: line;
    background-color:#fcca00;
    height: 0.3em;
}
.listcontent {
    grid-area: listcontent;
}

h3 {
    padding: 0;
    margin-top: 0.5em;
    margin-bottom: 0;
    font-size: 16pt;
}

.fastighet {
    margin-top: 0.3em;
    margin-right: 0.3em;
    width: 16em;
    font-size: 14pt;
    display: inline-block;
    vertical-align: top;
    background-color: #fcca00;
    cursor: pointer;
    padding: 0.2em;
    &:hover {
        background-color: lightgreen;
    }
    .beskr {
        font-size: 75%;
    }
}